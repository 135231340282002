<template>
    <NotificacaoForm v-if="isCadastrarOuEditar()" :id="notificacaoId" />
    <NotificacaoList v-if="!isCadastrarOuEditar()" />
</template>

<script>
import NotificacaoForm from '../../components/cadastro/NotificacaoForm.vue';
import NotificacaoList from '../../components/cadastro/NotificacaoList.vue';

export default {
    name: 'NotificacaoPage',
    components: { NotificacaoForm, NotificacaoList },
    
    data() {
        return {
            isCadastro: false,
            isEditar: false,
            notificacaoId: null
        }
    },
    
    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }                
    },

    methods: {         

        editar(notificacaoId) {
            if(notificacaoId) {
                this.notificacaoId = notificacaoId;
                this.isEditar = true;
            }
            else {
                this.notificacaoId = null;
                this.isEditar = false;
            }
        },

        isCadastrarOuEditar() {
            return this.isCadastro || this.isEditar;
        },

        validarRouter() {
            const routerName = this.$route.name
            if(routerName === 'notificacao-cadastro') {
                this.isCadastro = true;
            }
            else if(routerName === 'notificacao-editar') {
                const notificacaoId = this.$route.params.notificacaoId;
                this.editar(notificacaoId);
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.notificacaoId = null;
            }
        }
    }
}
</script>
