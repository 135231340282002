<template>
    <div class="card">
        <div class="flex align-items-center">
           <i class="pi pi-check-square mr-2" style="font-size: 1.4rem"></i>
            <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Notificação</h3>
        </div>
        <hr />
        <!--Inputs com labels de id, operação, data de cadastro, data da atualização, tudo um do lado do outro-->
        <div class="grid p-fluid formgrid">
            <div class="col-1 lg:col-1">
                <div class="field">
                    <label for="id"><strong>ID</strong></label>
                    <InputText id="id" class="w-full" v-model="dados.id" :disabled="true" />
                </div>
            </div>
            <div class="col-2 lg:col-4">
                <div class="field">
                    <label for="operacoesId">
                        <strong>Operações</strong>
                        <span style="color:red;">&nbsp;*</span>
                    </label>
                    <AutoComplete 
                        v-model="dados.operacao" 
                        inputId="operacoesId" 
                        field="descricao"
                        :dropdown="true"
                        @complete="buscarOperacoes" 
                        :suggestions="operacoesFiltro" 
                        :class="{ 'p-invalid': isValidOperacao }"/>
                </div>
            </div>
            <div class="col-1 lg:col-2">
                <div class="field">
                    <label for="dataCadastro"><strong>Data de Cadastro</strong></label>
                    <Calendar id="calendar-cadastro" v-model="dados.dataCadastro" showTime hourFormat="24" disabled="true" />
                </div>
            </div>
            <div class="col-1 lg:col-2">
                <div class="field">
                    <label for="dataAtualizacao"><strong>Data de Atualização</strong></label>
                    <Calendar id="calendar-atualizacao" v-model="dados.dataAtualizacao" showTime hourFormat="24" disabled="true" />
                </div>
            </div>
        </div>

        <DataTable
            showGridlines
            selectionMode="single"
            responsiveLayout="stack"
            :value="dados.produtos"
            v-model:selection="notificacao"
            bodyClass="align-items-center"
            class="mb-4"
        >

            <template #header>
                Selecione os tipos de produtos
                <span style="color:red;">&nbsp;*</span>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="tipoProduto" header="Descrição" sortable/>

            <Column>
                <template #header>
                    <Checkbox :binary="true" @change="ativarOuDesativarTodosProdutos" v-model="ativarTodosProdutos"/>
                </template> 
                <template #body="{data}">
                    <Checkbox v-model="data.ativo" :binary="true" />
                </template>
            </Column>

        </DataTable>

        <DataTable
            showGridlines
            selectionMode="single"
            responsiveLayout="stack"
            :value="dados.destinatarios" 
            v-model:selection="notificacao"
            bodyClass="align-items-center">

            <template #header>
                Destinatários/Responsáveis
                <span style="color:red;">&nbsp;*</span>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="nome" header="Nome" sortable/>

            <Column field="email" header="Email"/>           
            
            <Column header="Opções" style="width:1rem;">
                <template #body="{ index }">
                    <div class="flex-none">
                        <Button icon="pi pi-times" class="p-button-rounded flex-none p-button-danger" title="Remover" @click="remover(index)"></Button>
                    </div>
                </template>
            </Column>

            <template #footer>
                <div>
                    <Button 
                        icon="pi pi-plus"
                        label="Incluir"
                        class="flex-none p-button-info p-button-outlined"
                        title="Incluir"
                        @click="abrirDestinatarioDialog"
                    ></Button>
                </div>
            </template>
        </DataTable>

        <div class="col-12 lg:col-5">
            <div class="field">
                <label for="assunto">
                    <strong>Assunto</strong>
                    <span style="color:red;">&nbsp;*</span>
                </label>
                <InputText v-model="dados.assunto" id="assunto" class="w-full" :class="{ 'p-invalid': isValidAssunto }" />
            </div>
        </div>

        <div class="col-12 lg:col-12">
            <div class="field">
                <div class="flex align-items-center">
                    <label for="assunto">
                        <strong>Mensagem</strong>
                        <span style="color:red;">&nbsp;*</span>
                    </label>
                    <Button icon="pi" class="p-button ml-2" @click="visualizarInformacao('CCV_VARIAVEIS_MENSAGEM')">
                        <i class="pi pi-info-circle" style=""></i>
                    </Button>
                </div>
                <Editor v-model="dados.mensagem" editorStyle="height: 320px" :class="{ 'p-invalid': isValidMensagem }"/>
            </div>
        </div>

        <div class="flex justify-end mt-4">
            <Button label="Salvar" class="p-button-info mr-1" icon="pi pi-check" @click="salvar"></Button>
            <Button label="Cancelar" class="p-button-danger" icon="pi pi-times" @click="voltar"></Button>
        </div>
    </div>

    <Dialog modal v-model:visible="destinatarioDialog" :style="{width: '50w'}" @hide="fecharDestinatarioDialog">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">Adicionar Destinatário/Responsável</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-12">
                <div class="field">
                    <label for="data">
                        <strong>Nome</strong>
                    </label>
                    <InputText
                        v-model="destinatario.nome"
                        class="w-full"
                        :class="{ 'p-invalid': isValidNome }"
                    />
                </div>
            </div>

            <div class="col-12">
                <div class="field">
                    <label for="data">
                        <strong>Email</strong>
                    </label>
                    <InputText
                        v-model="destinatario.email"
                        class="w-full"
                        :class="{ 'p-invalid': isValidEmail }"
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info ml-1"
                    @click="salvarDestinatarioDialog"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fecharDestinatarioDialog"
                ></Button>
            </div>
        </template>
    </Dialog>

    <Modalinformacao 
        :visivel="informacaoDialog"
        :paginaArea="paginaArea"
        @fechar="ocultarInformacao()"
    />
</template>

<script>
import NotificacaoService from '../../service/NotificacaoService';
import TipoProdutoService from '../../service/TipoProdutoService';
import StorageService from '../../service/StorageService';
import Formatacao from '../../utilities/Formatacao';
import Modalinformacao from '../ModalInformacao.vue';

export default {
    components: { Modalinformacao },
    props: {
        id: {
            type: String,
            required: false
        },
    },

    data() {
        return {
            dados: {
                produtos: [],
                destinatarios: []
            },

            destinatarioDialog: false,
            destinatario: {},
            mensagem: {
                ativo: false,
                gravidade: "warn",
                conteudo: ""
            },

            isValidNome: false,
            isValidEmail: false,
            isValidOperacao: false,
            isValidAssunto: false,
            isValidMensagem: false,

            operacoesFiltro: [],
            operacoes: [],
            
            notificacao: null,
            ativarTodosProdutos: false,
            
            informacaoDialog:false,
            paginaArea:''
        }
    },

    mounted() {
        this.getOperacoes();

        if(!this.id) {
            this.getTipoProdutos();
        }
        else {
            this.carregarFormulario();
        }
    },

    methods:{
        carregarFormulario() {
            NotificacaoService.buscarPorId(this.id)
                .then(({ data }) => {
                    this.dados = data;
                    this.dados["dataCadastro"] = Formatacao.formatDateCustom(data.dataCadastro, "DD/MM/YYYY");
                    this.dados["dataAtualizacao"] = Formatacao.formatDateCustom(data.dataAtualizacao, "DD/MM/YYYY");
                    this.getTipoProdutos(data.produtos);
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível carregar os dados do formulário',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        formatDate(data, format) {
            return Formatacao.formatDateCustom(data, format);
        },

        remover(indice) {
            this.dados.destinatarios.splice(indice, 1);
            this.$toast.add({
                severity: 'success',
                summary: 'Sucesso',
                detail: 'Destinatário removido com sucesso!',
                life: 7500
            });
        },

        validarCampos() {
            let campos = [];

            if(!this.dados.operacao) {
                campos.push("Operações");
                this.isValidOperacao = true;
            }
            else {
                this.isValidOperacao = false;
            }

            if(!this.dados.assunto) {
                campos.push("Assunto");
                this.isValidAssunto = true;
            }
            else {
                this.isValidAssunto = false;
            }

            if(!this.dados.mensagem) {
                campos.push("Mensagem");
                this.isValidMensagem = true;
            }
            else {
                this.isValidMensagem = false;
            }

            if(this.dados.destinatarios.length <= 0) {
                campos.push("Destinatários/Responsáveis");
            }
            
            if(!this.dados.produtos || this.dados.produtos.filter(i => { return i.ativo; }).length <= 0) {
                campos.push("Tipo de produto");
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar o contrato.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return campos.length === 0;
        },

        montarJson() {
            const obj = {};

            obj["id"] = this.id;
            obj["assunto"] = this.dados.assunto;
            obj["mensagem"] = this.dados.mensagem;
            obj["operacao"] = this.dados.operacao.codigo;
            obj["destinatarios"] = this.dados.destinatarios;
            obj["produtos"] = this.dados.produtos.filter(item => item.ativo);

            return obj;
        },

        salvar() {
            if(!this.validarCampos()) return;
            const dados = this.montarJson();
            NotificacaoService.salvar(dados)
                .then(({ data }) => {
                    const detalhe = 'A notificação foi salva com sucesso.';
                    StorageService.setMensagemToast("NOTIFICACAO", 'success', 'Notificação salva', detalhe, true);
                    this.voltar();
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar a notificação.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },
        
        voltar() {
            this.$router.push(`/notificacoes`);
        },

        buscarOperacoes(event) {
            if (!event.query.trim().length) {
                this.operacoesFiltro = [...this.operacoes];
            } 
            else {
                this.operacoesFiltro = this.operacoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        getOperacoes() {
            NotificacaoService.operacoes()
                .then(({ data }) => {
                    this.operacoes = data;
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível carregar as operações',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        getTipoProdutos(produtos = []) {
            TipoProdutoService.buscarTipoProdutoPorNome()
                .then(({ data }) => {
                    this.dados.produtos = data.map(item => {
                        const produtoAux = produtos.find(itemAux => { return itemAux.idTipoProduto === item.id; });
                        return {
                            idTipoProduto: item.id,
                            tipoProduto: item.nome,
                            ativo: produtoAux != null
                        }
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível carregar os tipos de produto',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        ativarOuDesativarTodosProdutos() {
            if(!this.dados.produtos) return;

            this.dados.produtos.forEach((produto) => {
                produto.ativo = this.ativarTodosProdutos;
            });
        },

        abrirDestinatarioDialog() {
            this.destinatarioDialog = true;
        },

        fecharDestinatarioDialog() {
            this.destinatarioDialog = false;
            this.isValidNome = false;
            this.isValidEmail = false;
            this.destinatario = {};
            this.mensagem = {
                ativo: false,
                gravidade: "warn",
                conteudo: ""
            }
        },

        salvarDestinatarioDialog() {
            let isPreenchido = true;

            if(!this.destinatario.nome) {
                this.isValidNome = true;
                isPreenchido = false;
            }
            else {
                this.isValidNome = false;
            }
            
            const validarEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            if(!this.destinatario.email || !validarEmail.test(this.destinatario.email)) {
                this.isValidEmail = true;
                isPreenchido = false;
            }
            else {
                this.isValidEmail = false;
            }

            if(!isPreenchido) {
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: 'Por favor, preencher os campos obrigatórios!'
                }

                return;
            }

            const destinatario = Object.assign({}, this.destinatario);
            this.dados.destinatarios.push(destinatario);

            this.isValidNome = false;
            this.isValidEmail = false;
            this.destinatario = {};
            this.mensagem = {
                ativo: true,
                gravidade: "success",
                conteudo: "Adicionado com sucesso!"
            }
        },

        ocultarInformacao(){
            this.informacaoDialog = false;
        },

        visualizarInformacao(paginaArea){
            this.paginaArea = paginaArea;
            this.informacaoDialog = true;
        },
    }
}
</script>