<template>
    <div class="card">
        <div class="flex align-items-center">
            <i class="pi pi-building" style="font-size: 1.4rem"></i> 
           <h3 style="margin:0px 5px;">Notificações</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="dados"
            v-model:selection="notificacaoSelecionada"
            selectionMode="single"
            @sort="sort($event)"
            showGridlines
        >

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2" style="width: 15rem;">
                        <label for="filtro-operacao">
                            <strong>Operação</strong>
                        </label>
                        <AutoComplete 
                            placeholder="Selecionar"
                            v-model="filter.operacao" 
                            id="filtro-operacao" 
                            field="descricao"
                            class="w-full"
                            :dropdown="true"
                            :suggestions="operacoesFiltro" 
                            @complete="buscarOperacoes" 
                        />
                    </div>

                    <div class="field mr-2" style="width: 15rem;">
                        <label for="filtro-tipo-produto">
                            <strong>Tipo Produto</strong>
                        </label>
                        <AutoComplete 
                            placeholder="Selecionar"
                            v-model="filter.tipoProduto" 
                            id="filtro-tipo-produto" 
                            field="nome"
                            class="w-full"
                            :dropdown="true"
                            :suggestions="tiposProdutosFiltrados" 
                            @complete="buscarTiposProdutos" 
                        />
                    </div>

                    <div class="field mr-2" style="width: 15rem;">
                        <label for="filtro-destinatarios">
                            <strong>Destinatários/Responsáveis</strong>
                        </label>
                        <InputText 
                            id="filtro-destinatarios" 
                            v-model="filter.destinatarios" 
                            class="w-full"
                        />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar ativos"
                        class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>

                    <Button 
                        label="Cadastrar"
                        icon="pi pi-plus"
                        class="p-button-info mr-1 mt-2"
                        @click="cadastrar()"
                    ></Button>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="ID" />
            <Column field="operacao" header="Operação" sortable>
                <template #body="{ data, field }">
                    {{ data[field].descricao }}
                </template>
            </Column>

            <Column field="produtos" header="Tipo Produto" />
            <Column field="destinatarios" header="Destinatários/Responsáveis" />

            <Column header="Atualização">
                <template #body="{ data }">
                    {{ formatDateCustom(data.atualizacao, 'DD/MM/YYYY') }}
                </template>
            </Column>


            <Column header="Situação">
                <template #body="{ data }">
                    {{ data.ativo ? "Ativo" : "Inativo" }}
                </template>
            </Column>

            <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>
                        
                        <Button
                            v-if="filter.situacao == 'ATIVO'"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none  p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Deseja <strong>{{ notificacaoSelecionada.ativo ? 'inativar' : 'ativar' }}</strong> a notificação <strong>{{ notificacaoSelecionada.operacao.descricao }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ModalHistoricoGenerico
        codigoPagina="NOTIFICACAO"
        :id="notificacaoSelecionada.id"
        :descricao="notificacaoSelecionadaDescricao"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import Formatacao from '../../utilities/Formatacao';
import StorageService from '../../service/StorageService';
import NotificacaoService from '../../service/NotificacaoService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';
import TipoProdutoService from '../../service/TipoProdutoService';

export default {
    name: 'NotificacaoList',
    components: { ModalHistoricoGenerico },
    data() {
        return {
            dados: [],

            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,

            filter: {},
            ordenar: 'id,asc',

            operacoesFiltro: [],
            operacoes: [],

            tiposProdutosFiltrados: [],
            
            notificacaoSelecionadaDescricao: null,
            notificacaoSelecionada: {},
            ativarOuInativarDialog: false,

            historicoDialog: false,
            
            storange: StorageService.getControlePagina("NOTIFICACAO"),
            storangeMessage: StorageService.getMensagemToast("NOTIFICACAO")
        }
    },

    created() {
        this.getOperacoes();

        //verifica se o filtro está vazio
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        cadastrar() {
            this.$router.push(`/notificacao/cadastro`);
        },

        editar(notificacaoId) {                
            this.$router.push(`/notificacao/editar/${ notificacaoId }`);
        },

        construirFiltro() {
            const params = {};
            params["situacao"] = this.filter.situacao;
            params["page"] = this.pagina;
            params["size"] = this.qtdRegistroPorPagina;
            params["sort"] = this.ordenar;

            if(!!this.filter.operacao) {
                params["operacao"] = this.filter.operacao.codigo;
            }
            else {
                params["operacao"] = undefined;
            }

            if(!!this.filter.tipoProduto) {
                params["idTipoProduto"] = this.filter.tipoProduto.id;
            }
            else {
                params["idTipoProduto"] = undefined;
            }

            if(!!this.filter.destinatarios) {
                params["destinatario"] = this.filter.destinatarios;
            }
            else {
                params["destinatario"] = undefined;
            }

            return params;
        },

        carregarDados() {
            NotificacaoService.buscarComPaginacao(this.construirFiltro())
                .then(({ data }) => {
                    if(data) {
                        this.dados = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar notificações',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
            StorageService.setControlePaginaFilter("NOTIFICACAO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        limparFiltro() {
            this.filter = {};
            this.filter.situacao = "ATIVO";
            this.carregarDados();
        },

        confirmarAtivarOuInativar(notificacao) {
            this.notificacaoSelecionada = notificacao;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if(this.notificacaoSelecionada.ativo) {
                NotificacaoService.inativar(this.notificacaoSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`A notificação foi inativada!`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.notificacaoSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                NotificacaoService.ativar(this.notificacaoSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`A notificação foi ativada!`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.notificacaoSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        visualizarHistorico(notificacao) {
            this.historicoDialog = true;
            this.notificacaoSelecionada = notificacao;
            this.notificacaoSelecionadaDescricao = "Notificação: " + (!!notificacao.operacao ? notificacao.operacao.descricao : "");
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.notificacaoSelecionada = {};
        },

        exibirMensagemToast() {
            if (this.storangeMessage.active) {
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail, 
                    life: 7500
                });
                StorageService.setMensagemToast("NOTIFICACAO", 'info', '', '', false);
            }
        },

        buscarOperacoes(event) {
            const descricao = !event.query.trim().length ? null : event.query;
            if (!!descricao) {
                this.operacoesFiltro = this.operacoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(descricao.toLowerCase());
                });
    
                if("todos".startsWith(descricao.toLowerCase())) this.operacoesFiltro.unshift({ descricao: "Todos" });
            } 
            else {
                this.operacoesFiltro = [...this.operacoes];
                this.operacoesFiltro.unshift({ descricao: "Todas" });
            }
        },

        getOperacoes() {
            NotificacaoService.operacoes()
                .then(({ data }) => {
                    this.operacoes = data;
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível carregar as operações',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        buscarTiposProdutos(event) {
            const nome = !event.query.trim().length ? null : event.query;
            TipoProdutoService.buscarTipoProdutoPorNome(nome)
                .then(({ data }) => {
                    if(data) {
                        this.tiposProdutosFiltrados = data;
                        this.tiposProdutosFiltrados.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))

                        if(nome == null || "todos".startsWith(nome.toLowerCase())) {
                            this.tiposProdutosFiltrados.unshift({ nome: "Todos" });
                        }
                    }
                })
                .catch(error => {
                    this.tiposProdutosFiltrados = [];
                });
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
